exports.components = {
  "component---src-pages-404-index-jsx": () => import("./../../../src/pages/404/index.jsx" /* webpackChunkName: "component---src-pages-404-index-jsx" */),
  "component---src-pages-contact-thanks-index-jsx": () => import("./../../../src/pages/contact/thanks/index.jsx" /* webpackChunkName: "component---src-pages-contact-thanks-index-jsx" */),
  "component---src-pages-foot-care-1-index-jsx": () => import("./../../../src/pages/foot-care-1/index.jsx" /* webpackChunkName: "component---src-pages-foot-care-1-index-jsx" */),
  "component---src-pages-foot-care-2-index-jsx": () => import("./../../../src/pages/foot-care-2/index.jsx" /* webpackChunkName: "component---src-pages-foot-care-2-index-jsx" */),
  "component---src-pages-foot-care-3-index-jsx": () => import("./../../../src/pages/foot-care-3/index.jsx" /* webpackChunkName: "component---src-pages-foot-care-3-index-jsx" */),
  "component---src-pages-foot-care-4-index-jsx": () => import("./../../../src/pages/foot-care-4/index.jsx" /* webpackChunkName: "component---src-pages-foot-care-4-index-jsx" */),
  "component---src-pages-foot-care-5-index-jsx": () => import("./../../../src/pages/foot-care-5/index.jsx" /* webpackChunkName: "component---src-pages-foot-care-5-index-jsx" */),
  "component---src-pages-foot-care-6-index-jsx": () => import("./../../../src/pages/foot-care-6/index.jsx" /* webpackChunkName: "component---src-pages-foot-care-6-index-jsx" */),
  "component---src-pages-information-index-jsx": () => import("./../../../src/pages/information/index.jsx" /* webpackChunkName: "component---src-pages-information-index-jsx" */),
  "component---src-pages-strengths-index-jsx": () => import("./../../../src/pages/strengths/index.jsx" /* webpackChunkName: "component---src-pages-strengths-index-jsx" */),
  "component---src-templates-0-article-index-jsx": () => import("./../../../src/templates/0_article/index.jsx" /* webpackChunkName: "component---src-templates-0-article-index-jsx" */),
  "component---src-templates-1-top-index-jsx": () => import("./../../../src/templates/1_top/index.jsx" /* webpackChunkName: "component---src-templates-1-top-index-jsx" */),
  "component---src-templates-4-blog-index-jsx": () => import("./../../../src/templates/4_blog/index.jsx" /* webpackChunkName: "component---src-templates-4-blog-index-jsx" */),
  "component---src-templates-5-privacy-index-jsx": () => import("./../../../src/templates/5_privacy/index.jsx" /* webpackChunkName: "component---src-templates-5-privacy-index-jsx" */),
  "component---src-templates-6-contact-index-jsx": () => import("./../../../src/templates/6_contact/index.jsx" /* webpackChunkName: "component---src-templates-6-contact-index-jsx" */)
}

